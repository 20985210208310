<template>
  <div>
    <v-list-item
      v-for="(address, index) in addresses"
      :key="address.addressId"
      @click="setAddress(address, index)"
    >
      <!-- selected icon -->
      <v-list-item-avatar>
        <!-- v-if="cart.shippingAddress.addressId == address.addressId" -->
        <v-icon v-if="shippingAddressSelected == index">$radioChecked</v-icon>
        <v-icon v-else>$radioUnchecked</v-icon>
      </v-list-item-avatar>
      <img
        class="mr-3"
        v-if="showServiceIcon"
        :src="$t('navbar.service.' + address.deliveryServiceId + '.textIcon')"
        height="50"
        width="50"
      />
      <div
        class="d-flex align-items justify-space-between w-100 flex-column flex-sm-row"
      >
        <!-- address name -->
        <v-list-item-content class="w-100 align-self-center">
          <v-list-item-title
            class="font-weight-bold primary--text text--lighten-1"
          >
            {{ address.addressName }}
          </v-list-item-title>
          <v-list-item-subtitle class="text--color">
            {{
              $t("navbar.address." + address.addressTypeId + ".list", address)
            }}
            <span v-if="address.zone.zoneGroupId==5 && $vuetify.breakpoint.smAndDown"><br><strong>{{ $t("cart.checkoutSummary.deliveryShopopop") }}</strong></span>
            <span v-else-if="address.zone.zoneGroupId==5"> - <strong>{{ $t("cart.checkoutSummary.deliveryShopopop") }}</strong></span>
          </v-list-item-subtitle>
        </v-list-item-content>

        <div class="align-self-start align-self-sm-center">
          <!-- favourite -->
          <v-list-item-action
            class="flex-row align-center"
            v-if="
              address.addressId == cart.user.defaultStoreAddressId ||
                address.addressId == cart.user.defaultDeliveryAddressId
            "
          >
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">mdi-star-outline</v-icon>
              </template>
              <span>{{
                address.addressType == "home"
                  ? "Indirizzo preferito"
                  : "Negozio preferito"
              }}</span>
            </v-tooltip>
          </v-list-item-action>

          <!-- address class icon -->
          <img
            class="ml-1 align-center"
            v-for="(repartoIcon, idx) in filteredAddressClass(
              address.addressClass
            )"
            :key="idx"
            :src="repartoIcon.iconSource"
            :alt="repartoIcon.name"
            height="20"
            width="20"
          />
        </div>
      </div>
    </v-list-item>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "AddressListItem",
  data() {
    return {};
  },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    shippingAddressSelected: {
      type: Number,
      required: false,
      default: 0
    },
    cart: {
      type: Object,
      required: true
    },
    showServiceIcon: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    setAddress(address, index) {
      this.$emit("setAddress", { address: address, index: index });
    },
    filteredAddressClass(addressClass) {
      let result = [];
      if (addressClass) {
        result = addressClass.filter(item => {
          return !(
            item.iconSource === null || item.iconSource.trim().length === 0
          );
        });
      }
      return result;
    }
  }
};
</script>

<style scoped lang="scss">
.v-list-item {
  border-bottom: 1px solid var(--v-grey-lighten3);
  .v-list-item__content {
    .v-list-item__title {
      font-size: 15px;
      line-height: 1.5;
    }
  }
}

.theme--light.v-list-item--active::before {
  opacity: 0;
}
</style>
