<template functional>
  <div class="product-price">
    <div v-if="props.product.priceDisplay" class="price-container">
      <div
        v-if="
          props.product.warehousePromo &&
            props.product.warehousePromo.view &&
            props.product.warehousePromo.view.bubble &&
            props.product.warehousePromo.view.bubble === 'hidden'
        "
      ></div>
      <template v-else>
        <div class="old-price">
          <span v-if="props.product.priceStandardDisplay">{{
            parent.$n(props.product.priceStandardDisplay, "currency")
          }}</span>
        </div>
      </template>
      <div class="price-cnt d-flex flex-row justify-space-between">
        <div
          class="cur-price"
          :class="{ 'promo-price': props.product.priceStandardDisplay }"
        >
          {{ parent.$n(props.product.priceDisplay, "currency")
          }}<span
            class="small text-lowercase"
            v-if="props.product.productInfos.TIPOLOGIA != 'Pezzo'"
            >/{{ props.product.weightUnitDisplay }}</span
          >
        </div>

        <div v-if="props.showDescrWeight" class="product-descr-weight">
          <span
            class="weight-unit"
            v-if="
              props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                props.product.priceUmDisplay
            "
          >
            {{ parent.$n(props.product.priceUmDisplay, "currency") }}/{{
              props.product.weightUnitDisplay
            }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ProductPrice",
  props: {
    product: {
      type: Object,
      required: true
    },
    showDescrWeight: {
      type: Boolean,
      default: true
    }
  }
};
</script>
