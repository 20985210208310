<template>
  <v-card flat elevation="0" class="product-option-selector pa-3 pa-sm-6">
    <h3 class="text-center my-3" v-if="showTitle">
      E' necessario selezionare tutte le opzioni
    </h3>
    <div v-for="option in optionsArray" :key="option.parameterName">
      <v-radio-group
        v-if="mode === 'radio'"
        dense
        v-model="selectedOptions[option.parameterName]"
        row
        @change="optionChanged(option)"
      >
        <v-radio
          :value="opt.value"
          v-for="opt in option.options"
          :key="opt.name"
          ><template v-slot:label>
            <span class="">{{ opt.key }}</span>
          </template></v-radio
        >
      </v-radio-group>
      <v-select
        v-if="mode === 'select'"
        outlined
        dense
        v-model="selectedOptions[option.parameterName]"
        :items="option.options"
        :label="option.name"
        item-text="key"
        item-value="value"
        @change="optionChanged(option)"
      />
    </div>
    <v-card-actions class="d-flex flex-row justify-center">
      <v-btn class="secondary" text @click="addToCart" :disabled="!isValid">
        aggiungi al carrello
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "ProductOptionSelector",
  data() {
    return {
      selectedOptions: {},
      optionsArray: []
    };
  },
  props: {
    optionsToSelect: { type: Array, required: true },
    mode: { type: String, default: "select" },
    showTitle: { type: Boolean, default: true }
  },
  computed: {
    isValid() {
      return (
        Object.keys(this.selectedOptions).length === this.optionsToSelect.length
      );
    }
  },
  methods: {
    optionChanged(option) {
      this.$emit("option-changed", option);
    },
    addToCart() {
      this.$emit("submit", this.selectedOptions);
    }
  },
  mounted() {
    this.optionsArray = cloneDeep(this.optionsToSelect);
  }
};
</script>
