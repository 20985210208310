import { mapGetters, mapState } from "vuex";
import get from "lodash/get";

export default {
  props: {
    color: { type: String, default: "color" }
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      getDeliveryServiceId: "cart/getDeliveryServiceId"
    }),
    shippingAddress() {
      let shippingAddress = get(this.cart, "shippingAddress");
      if (shippingAddress) {
        return this.$t(
          "navbar.address." + shippingAddress.addressTypeId + ".format",
          shippingAddress
        );
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    timeslot() {
      let timeslot = get(this.cart, "timeslot");
      let forcedPreviewDate = get(this.cart, "forcedPreviewDate");

      if (!timeslot || timeslot.autoAssigned) {
        return this.$t("navbar.noTimeslot");
      } else {
        let dateFormat = this.$te("navbar.timeslot.dateFormat")
          ? this.$t("navbar.timeslot.dateFormat")
          : "DD MMMM";
        var date;
        if (this.$dayjs) {
          date = this.$dayjs(timeslot.date).format(dateFormat);
        } else {
          date = this.$te("navbar.timeslot.dateFormat")
            ? this.$DateTime
                .fromISO(timeslot.date)
                .toFormat(this.$t("navbar.timeslot.dateFormat"))
            : this.$DateTime.fromISO(timeslot.date).toFormat("dd MMMM");
        }

        if (this.$te("navbar.timeslot.format")) {
          return this.$t("navbar.timeslot.format", {
            date: date,
            beginTime: timeslot.beginTime,
            endTime: timeslot.endTime,
            name: timeslot.name
          });
        }

        if (forcedPreviewDate) {
          var forcedPreviewDateFormatted = "";
          if (this.$dayjs) {
            forcedPreviewDateFormatted = this.$dayjs(forcedPreviewDate).format(
              dateFormat
            );
          } else {
            forcedPreviewDateFormatted = this.$te("navbar.timeslot.dateFormat")
              ? this.$DateTime
                  .fromISO(forcedPreviewDate)
                  .toFormat(this.$t("navbar.timeslot.dateFormat"))
              : this.$DateTime.fromISO(forcedPreviewDate).toFormat("dd MMMM");
          }
          return (
            date +
            " (" +
            forcedPreviewDateFormatted +
            ") | " +
            timeslot.beginTime +
            " - " +
            timeslot.endTime
          );
        } else {
          return date + " | " + timeslot.beginTime + " - " + timeslot.endTime;
        }
      }
    },
    serviceImg() {
      return this.$t("navbar.service." + this.getDeliveryServiceId + ".icon", {
        color: this.color
      });
    },
    serviceName() {
      return this.$t("navbar.service." + this.getDeliveryServiceId + ".name");
    },
    timeslotName() {
      let shippingAddress = get(this.cart, "shippingAddress");
      if (shippingAddress) {
        return this.$t(
          "navbar.address." +
            shippingAddress.addressTypeId +
            ".timeslotButtonTitle"
        );
      } else {
        return this.$t("navbar.noShippingAddress");
      }
    },
    timeslotImg() {
      return this.$t("navbar.service." + this.getDeliveryServiceId + ".icon", {
        color: this.color
      });
    },
    editableAddress() {
      return !(this.$route.name == "Payment" || this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    }
  },
  methods: {
    async openAddressSelector() {
      if (this.editableAddress) {
        if (await this.setAddress()) {
          await this.needTimeslot();
        }
      }
    },
    async openTimeslotSelector() {
      if (this.editableTimeslot) {
        await this.setTimeslot();
      }
    },
    getLegalById(legalId) {
      let user = this.cart.user;
      if (!user || !user.legals) {
        return false;
      }
      for (var i = 0; i < user.legals.length; i++) {
        if (user.legals[i].legalId == legalId) {
          return true;
        }
      }
      return false;
    },
    getCrmSegmentId(crmSegmentId) {
      let user = this.cart.user;
      if (user && typeof user.crmUserSegments == "object") {
        return user.crmUserSegments.find(
          segment => segment.crmSegment.crmSegmentId == crmSegmentId
        );
      }
      return null;
    }
  }
};
