<template>
  <v-row no-gutters class="cart-info-row">
    <v-btn
      icon
      small
      class="service-icon-btn mt-1 mr-4"
      @click="openTimeslotSelector"
    >
      <v-img
        :src="`/services-icons/${serviceImage}.svg`"
        max-width="40"
      ></v-img>
    </v-btn>
    <div
      class="flex-column right-col"
      :class="homeComponent ? 'd-flex' : 'd-none d-md-flex'"
    >
      <v-btn
        class="cart-info-button bold"
        :disabled="!editableAddress"
        text
        x-small
        block
        color="white"
        elevation="0"
        @click="openAddressSelector"
      >
        <span>{{ shippingAddress }} </span>
        <v-icon color="secondary" right v-if="editableAddress">$edit</v-icon>
      </v-btn>
      <v-btn
        class="cart-info-button"
        :disabled="!editableTimeslot"
        text
        x-small
        color="white"
        elevation="0"
        @click="openTimeslotSelector"
      >
        <span>{{ timeslot }}</span>
        <v-icon color="secondary" right v-if="editableTimeslot"
          >$edit</v-icon
        ></v-btn
      >
    </div>
  </v-row>
</template>
<style scoped lang="scss">
.cart-info-row {
  flex-wrap: nowrap;
  .right-col {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      max-width: 90%;
    }
    .v-btn {
      width: 100%;
      :deep(.v-btn__content) {
        width: 100% !important;
        span {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }
}
.cart-info-button {
  background: transparent !important;
  justify-content: flex-start;
  .v-btn__content {
    max-width: 100%;
    span {
      font-weight: 400;
      font-size: 13px;
      overflow: hidden;
      text-overflow: ellipsis;
      // @media #{map-get($display-breakpoints, 'xs-only')} {
      //   max-width: 100%;
      // }
      // @media #{map-get($display-breakpoints, 'lg-only')} {
      //   max-width: 226px;
      // }
      // @media #{map-get($display-breakpoints, 'xs-only')} {
      //   max-width: 177px;
      // }
    }
    .v-icon {
      font-size: 12px;
    }
  }

  &.bold {
    .v-btn__content {
      span {
        font-weight: 700;
      }
    }
  }
}
</style>
<script>
import cartInfo from "~/mixins/cartInfo";
import login from "~/mixins/login";
import { mapGetters, mapState } from "vuex";
export default {
  mixins: [cartInfo, login],
  props: {
    homeComponent: { type: Boolean, default: false }
  },
  data() {
    return {
      serviceImage: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated"
    }),
    ...mapState({
      cart: ({ cart }) => cart.cart
    })
  },
  methods: {
    async openTimeSlotSelector() {
      await this.setTimeslot();
    },
    setServiceImage(serviceId) {
      if (!serviceId) serviceId = this.cart.shippingAddress.deliveryServiceId;
      if (serviceId === 1) this.serviceImage = "clicca-e-ritira-neg";
      if (serviceId === 2) this.serviceImage = "consegna-a-casa-neg";
      if (serviceId === 4) this.serviceImage = "locker-bianco";
    }
  },
  created() {
    this.setServiceImage();
  },
  watch: {
    "cart.shippingAddress.deliveryServiceId": function(val) {
      this.setServiceImage(val);
    }
  }
};
</script>
