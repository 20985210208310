import axios from "~/plugins/axios";

export default {
  samlLogin(param) {
    let params = new URLSearchParams();
    let url = window.location.href;
    if (param && param.additionalUrl) {
      url += param.additionalUrl;
    }
    params.append("relay_state_url", url);
    return axios
      .get("/ebsn/api/saml/do-login", {
        params: { relay_state_url: url }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          return data.data.data;
        } else {
          return Promise.reject(data.data.response.message);
        }
      })
      .catch(error => {
        return Promise.reject(error);
      });
  },
  samlLogout() {
    let params = new URLSearchParams();
    params.append("relay_state_url", window.location.href);
    return axios
      .get("/ebsn/api/saml/do-logout", {
        params: { relay_state_url: window.location.href }
      })
      .then(data => {
        if (data.data.response.status == 0) {
          localStorage.removeItem("X-Ebsn-Account");
          localStorage.removeItem("X-Ebsn-Session");
        }
        return data.data.data;
      })
      .catch(data => {
        return data.response;
      });
  },
  googleLogin() {
    return axios
      .get("/api/social-redir/google-mobile")
      .then(data => {
        return data.data;
      })
      .catch(data => {
        return data.response;
      });
  },
  openGoogleLoginPage() {
    let width = 600;
    let height = 600;
    let left = window.outerWidth / 2 - width / 2;
    let top = window.outerHeight / 2 - height / 2;

    let redirectBaseUrl = `${window.location.protocol}//${
      window.location.hostname
    }${
      window.location.port !== "80" &&
      window.location.port !== "443" &&
      window.location.port &&
      window.location.port !== ""
        ? ":" + window.location.port
        : ""
    }`;

    let win = window.open(
      "https://accounts.google.com/o/oauth2/auth?client_id=" +
        global.config.googleClientId +
        "&redirect_uri=" +
        redirectBaseUrl +
        "/ebsn/api/social-redir/google&prompt=consent&response_type=code&scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
      "",
      "width=" +
        width +
        ",height=" +
        height +
        ",scrollbars=no,resizable=no,menubar=no,location=no"
    );
    win.moveTo(left, top);
  },
  handleGoogleOnApp(win, customPageUrl) {
    console.log("INTO HANDLE OPENAPP!!");
    var localRef = {};
    var browserParams =
      "location=no,toolbar=yes,zoom=no,hardwareback=no,enableViewportScale=yes";
    var params = {
      backendUrl: process.env.VUE_APP_EBSN_URL.replace(/(^\w+:|^)\/\//, ""),
      backendContext: "ebsn"
    };

    window.localStorage.setItem(
      "windowOpenerLocation",
      JSON.stringify(window.location)
    );

    window.localStorage.setItem("windowOpenerClosed", window.closed);

    localRef.inAppBrowserRef = window.open(win, "_blank", browserParams);

    if (localRef.inAppBrowserRef) {
      localRef.inAppBrowserRef.addEventListener("loadstart", function(args) {
        var url = args.url;

        if (
          url.indexOf(params.backendUrl) >= 0 &&
          url.indexOf(params.backendContext) >= 0 &&
          url.indexOf("registration") >= 0
        ) {
          // var query_params = url.split("?");
          // for (var i = 0; i < query_params.length; i++) {
          //   if (query_params[i].indexOf("social_login=") > -1) {
          //     params.query = query_params[i];
          //     break;
          //   }
          // }
          if (customPageUrl) {
            params.registration = url.indexOf(customPageUrl) > 0 ? true : false;
            localRef.inAppBrowserRef.close();
          } else {
            params.registration =
              url.indexOf("registration-user") > 0 ? true : false;
            localRef.inAppBrowserRef.close();
          }
        } else {
          if (
            (url.indexOf(params.backendUrl) >= 0 &&
              url.endsWith(params.backendUrl)) ||
            (url.indexOf(params.backendUrl) >= 0 &&
              url.endsWith(params.backendUrl + "shop"))
          ) {
            params.login = true;
            localRef.inAppBrowserRef.close();
          }
        }
      });
      localRef.inAppBrowserRef.addEventListener("exit", function() {
        setTimeout(() => {
          window.localStorage.removeItem("windowOpenerLocation");
          window.localStorage.removeItem("windowOpenerClosed");
          if (params.registration) {
            if (customPageUrl) {
              global.vm.$router
                .push({
                  path: "/" + customPageUrl,
                  query: { social_login: "google" }
                })
                .catch(err => err);
            } else {
              global.vm.$router
                .push({
                  path: "/registration-user",
                  query: { social_login: "google" }
                })
                .catch(err => err);
            }
          } else {
            if (params.login) {
              global.vm.$router
                .push("/profile/dashboard", window.location.reload())
                .catch(err => err);
            }
          }
        }, 100);
      });
    }
  },
  getSocialUserDetails() {
    return axios
      .get("/ebsn/api/registration/social_user_detail")
      .then(data => {
        return data.data;
      })
      .catch(data => {
        return data.response;
      });
  },
  facebookLoginConnect(code, usePlugin) {
    return axios
      .get("/ebsn/api/social-redir/facebook-mobile", {
        params: { code: code, "use-plugin": usePlugin }
      })
      .then(data => {
        return data.data;
      })
      .catch(data => {
        return data.data.response;
      });
  },
  facebookLogin(code, usePlugin) {
    let params = new URLSearchParams();
    params.append("code", code);
    params.append(("use-plugin", usePlugin));
    return axios
      .get("/ebsn/api/social-redir/facebook-mobile", {
        params: { relay_state_url: window.location.href }
      })
      .then(data => {
        return data.data;
      })
      .catch(data => {
        return data.response;
      });
  },

  getFacebookMobileLoginInitUrl(backendUrl) {
    return `${backendUrl}/ebsn/api/social-redir/facebook-mobile-init`;
  },

  openFacebookLoginPage(redirectBaseUrl) {
    var width = 600;
    var height = 600;
    var left = window.outerWidth / 2 - width / 2;
    var top = window.outerHeight / 2 - height / 2;
    if (!redirectBaseUrl) {
      redirectBaseUrl = `${window.location.protocol}//${
        window.location.hostname
      }${
        window.location.port !== "80" && window.location.port !== "443"
          ? ":" + window.location.port
          : ""
      }`;
    }
    var win = window.open(
      "https://www.facebook.com/dialog/oauth?client_id=" +
        global.config.facebookClientId +
        "&redirect_uri=" +
        redirectBaseUrl +
        "/ebsn/api/social-redir/facebook&scope=public_profile,email",
      "",
      "width=" +
        width +
        ",height=" +
        height +
        ",scrollbars=no,resizable=no,menubar=no,location=no"
    );
    win.moveTo(left, top);
  }
};
