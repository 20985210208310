import axios from "~/plugins/axios";

export default {
  getOrder(orderId, showReplaced) {
    var params = {
      order_id: orderId
    };

    if (showReplaced) params["show_replaced"] = true;

    return axios
      .get("/ebsn/api/order/view", {
        params: params
      })
      .then(data => data.data.data)
      .catch(error => error);
  },
  getOrders(
    page,
    pageSize,
    fetchDetails,
    showPending,
    forceRefresh,
    showReplaced,
    fromDate,
    toDate,
    fromGrossTotal,
    toGrossTotal
  ) {
    var params = {
      page: page ? page : 1,
      page_size: pageSize ? pageSize : 12,
      fetch_detail: fetchDetails ? false : fetchDetails,
      show_pending: showPending,
      show_replaced: showReplaced,
      from_date: fromDate,
      to_date: toDate,
      from_gross_total: fromGrossTotal,
      to_gross_total: toGrossTotal
    };
    if (forceRefresh) {
      //TODO
    }
    return axios
      .get("/ebsn/api/order/list", {
        params: params
      })
      .then(data => {
        if (data && data.data) {
          return data.data.data;
        } else {
          return {
            orders: [],
            page: null
          };
        }
      })
      .catch(error => error);
  },

  deleteOrder(orderId) {
    return axios
      .post("/ebsn/api/order/delete", null, {
        params: { order_id: orderId }
      })
      .then(data => {
        if (data && data.data) {
          global.EventBus.$emit("success", {
            message: global.vm.$t("order.removeOrder", {
              orderId
            })
          });
          return data.data.data;
        } else {
          return {
            orders: [],
            page: null
          };
        }
      });
  },
  editOrder(orderId) {
    return axios
      .post("/ebsn/api/order/modify", null, {
        params: { order_id: orderId }
      })
      .then(data => {
        if (data && data.data) {
          return data.data.data;
        } else {
          return {
            orders: [],
            page: null
          };
        }
      })
      .catch(error => error);
  },
  payOrder(orderId) {
    return axios.post("/ebsn/api/order/payment", null, {
      params: {
        order_id: orderId
      }
    });
  },
  modifyOrderPaymentType(orderId, paymentTypeId, paymentAuthId, paymentData) {
    return axios
      .post("/ebsn/api/order/modify-payment-type", null, {
        params: {
          order_id: orderId,
          payment_type_id: paymentTypeId,
          user_payment_auth_id: paymentAuthId,
          payment_data: JSON.stringify(paymentData)
        }
      })
      .then(data => data.data.data);
  },

  // fix for tosano prod che non va modifica pagamento con paypal, manca JSON.stringify??
  modifyPaypalOrderPaymentType(
    orderId,
    paymentTypeId,
    paymentAuthId,
    paymentData
  ) {
    return axios
      .post("/ebsn/api/order/modify-payment-type", null, {
        params: {
          order_id: orderId,
          payment_type_id: paymentTypeId,
          user_payment_auth_id: paymentAuthId,
          payment_data: JSON.stringify(paymentData)
        }
      })
      .then(data => data.data.data);
  },
  saveRatingOrder(order) {
    let url = order.noteId
      ? "/ebsn/api/note/order/update"
      : "/ebsn/api/note/order/add";
    return axios.post(url, order).then(data => data.data.data);
  },
  getRatingOrder(order) {
    return axios.post("/ebsn/api/note/list/", order).then(data => data.data);
  },
  getOrdersForAllUserShippingAddresses(
    page,
    pageSize,
    fetchDetails,
    fetchOnlySubOrdersMatchingFilter,
    showPending,
    forceRefresh,
    showReplaced,
    orderIdFilter,
    orderFromDateFilter,
    orderToDateFilter,
    subOrderIdFilter,
    subOrderWarehouseIdFilter,
    subOrderDeliveryFromDateFilter,
    subOrderDeliveryToDateFilter,
    shippingAddressIdFilter
  ) {
    var params = {
      page: page ? page : 1,
      page_size: pageSize ? pageSize : 12,
      fetch_detail: fetchDetails ? true : false,
      fetch_only_suborders_matching_filter: fetchOnlySubOrdersMatchingFilter
        ? true
        : false,
      show_pending: showPending,
      show_replaced: showReplaced,
      order_id_filter: orderIdFilter,
      order_from_date_filter: orderFromDateFilter,
      order_to_date_filter: orderToDateFilter,
      suborder_id_filter: subOrderIdFilter,
      suborder_warehouseid_filter: subOrderWarehouseIdFilter,
      suborder_delivery_from_date_filter: subOrderDeliveryFromDateFilter,
      suborder_delivery_to_date_filter: subOrderDeliveryToDateFilter,
      shipping_address_id_filter: shippingAddressIdFilter
    };
    if (forceRefresh) {
      //TODO
    }
    return axios
      .get("/ebsn/api/order/list-for-all-user-shipping-addresses", {
        params: params
      })
      .then(data => data.data.data);
  },
  getOrdersForPerson(
    page,
    pageSize,
    fetchDetails,
    fetchOnlySubOrdersMatchingFilter,
    showPending,
    forceRefresh,
    showReplaced,
    orderIdFilter,
    orderFromDateFilter,
    orderToDateFilter,
    subOrderIdFilter,
    subOrderWarehouseIdFilter,
    subOrderDeliveryFromDateFilter,
    subOrderDeliveryToDateFilter,
    shippingAddressIdFilter
  ) {
    var params = {
      page: page ? page : 1,
      page_size: pageSize ? pageSize : 12,
      fetch_detail: fetchDetails ? true : false,
      fetch_only_suborders_matching_filter: fetchOnlySubOrdersMatchingFilter
        ? true
        : false,
      show_pending: showPending,
      show_replaced: showReplaced,
      order_id_filter: orderIdFilter,
      order_from_date_filter: orderFromDateFilter,
      order_to_date_filter: orderToDateFilter,
      suborder_id_filter: subOrderIdFilter,
      suborder_warehouseid_filter: subOrderWarehouseIdFilter,
      suborder_delivery_from_date_filter: subOrderDeliveryFromDateFilter,
      suborder_delivery_to_date_filter: subOrderDeliveryToDateFilter,
      shipping_address_id_filter: shippingAddressIdFilter
    };
    if (forceRefresh) {
      //TODO
    }
    return axios
      .get("/ebsn/api/order/list-for-all-person-orders", {
        params: params
      })
      .then(data => data.data.data);
  },
  reserveParking(order, distance) {
    var params = {
      order_id: order.orderId,
      distance: distance
    };

    return axios
      .post("/ebsn/api/order/reserve", null, {
        params: params
      })
      .then(data => {
        data.data.parkingReservationMessage = {};
        if (data.data.response.status === 0) {
          global.EventBus.$emit(
            "parkReserved",
            data.data.response.infos[0].info
          );
          data.data.parkingReservationMessage = {
            success: true,
            title: "Informazioni",
            template: data.data.response.infos[0].info
          };
        } else if (
          data.data.response.errors &&
          data.data.response.errors.length > 0
        ) {
          global.EventBus.$emit(
            "parkNotReserved",
            data.data.response.errors[0].error
          );
          data.data.parkingReservationMessage = {
            success: false,
            title: "Attenzione",
            template: data.data.response.errors[0].error
          };
        } else {
          global.EventBus.$emit(
            "reservingParkError",
            "Si è verificato un errore imprevisto."
          );
          data.data.parkingReservationMessage = {
            success: false,
            title: "Errore",
            template: "Si è verificato un errore imprevisto."
          };
        }

        return data.data.parkingReservationMessage;
      });
  }
};
