import analyticsService from "~/service/analyticsService";
import CatalogService from "./catalogService";
import axios from "~/plugins/axios";

export default {
  getList(listId, calculateStock, wishlistTypeId) {
    const params = {
      list_id: listId,
      calculate_stock: calculateStock,
      show_sectors: false
    };
    if (wishlistTypeId) {
      params["wishlist_type_id"] = wishlistTypeId;
    }

    return axios
      .get("/ebsn/api/wishlist/view", {
        params: params
      })
      .then(data => data.data.data)
      .catch(error => error);
  },
  getLists(page, pageSize) {
    var params = { page: page, pageSize: pageSize };

    return axios
      .get("/ebsn/api/wishlist/list", {
        params: params
      })
      .then(data => {
        if (data && data.data) {
          return data.data.data.personalList;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      })
      .catch(error => error);
  },
  confirm(listId, addressId) {
    return axios
      .post("/ebsn/api/wishlist/confirm", undefined, {
        params: {
          address_id: addressId,
          list_id: listId
        }
      })
      .then(data => data.data.data);
  },

  renameList(listId, name) {
    return axios
      .post("/ebsn/api/wishlist/update", null, {
        params: { list_id: listId, name: name }
      })
      .then(data => {
        if (data && data.data) {
          return data.data.data.personalList;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  createList(name) {
    return axios
      .post("/ebsn/api/wishlist/insert", null, { params: { name: name } })
      .then(data => {
        if (data && data.data) {
          return data.data.data.personalList;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },

  deleteList(listId) {
    return axios
      .post("/ebsn/api/wishlist/delete", null, { params: { list_id: listId } })
      .then(data => {
        if (data && data.data) {
          return data.data.data.personalList;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  setDefaultList(listId) {
    return axios
      .post("/ebsn/api/wishlist/select", null, { params: { list_id: listId } })
      .then(data => {
        if (data && data.data) {
          return data.data.data.personalList;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  removeItemFromList(list, itemId, productId) {
    let params = {
      wishlistId: list.listId.toString(),
      items: [{ itemId: itemId, quantity: 0, productId: productId }],
      show_sectors: false
    };

    params.hash = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(0, 5);
    return axios
      .post("/ebsn/api/wishlist/delete_prods", params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          // analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  addProductToList(product, listId, quantity) {
    let decodedQuantity = CatalogService.encodeProductQuantity(
      product,
      quantity
    );
    return this.addProductsToList(listId, [
      { product: product, quantity: decodedQuantity, weight: decodedQuantity }
    ]);
  },

  addProductsToList(listId, items) {
    let params = { wishlistId: listId, show_sectors: false };
    params.items = [];
    for (var i = 0; i < items.length; i++) {
      let product = items[i].product;

      params.items.push({
        productId: product.productId,
        quantity:
          product.productInfos.TIPOLOGIA === "Sfuso"
            ? items[i].weight
            : items[i].quantity
      });
    }
    return axios.post("/ebsn/api/wishlist/add_prod", params).then(data => {
      if (data && data.data) {
        analyticsService.addProductToList(items);

        global.EventBus.$emit("success", {
          message: global.vm.$tc(
            "list.productAddToList",
            params.items ? params.items.length : 0
          )
        });
        return data.data.data;
      } else {
        return {
          lists: [],
          page: null
        };
      }
    });
  },
  emptyList(list, all = false) {
    const params = { wishlistId: list.listId };
    // let items = [];
    // factory.forEachListItem(list, function (item) {
    //   items.push({ itemId: item.itemId, quantity: 0, weight: 0, productId: item.product.productId });
    // });
    // params.items = items;

    params.items = [];
    for (var i = 0; i < list.wishlistItems.length; i++) {
      let item = list.wishlistItems[i];

      params.items.push({
        productId: item.product.productId,
        quantity: item.quantity,
        weight: item.weight,
        itemId: item.itemId
      });
    }

    // Appending INACTIVE wishlis items to be deleted from list
    if (all) {
      for (var j = 0; j < list.wishlistInactiveItems.length; j++) {
        let item = list.wishlistInactiveItems[j];

        params.items.push({
          productId: item.product.productId,
          quantity: item.quantity,
          weight: item.weight,
          itemId: item.itemId
        });
      }
    }
    return axios
      .post("/ebsn/api/wishlist/delete_prods", params, {
        params: { show_sectors: false }
      })
      .then(data => {
        if (data && data.data) {
          // analyticsService.removeProductToList();

          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  },
  updateProductsInList(listId, item, newQuantity) {
    const decodedQuantity = CatalogService.encodeProductQuantity(
      item.product,
      newQuantity
    );

    const params = {
      wishlistId: listId,
      items: [{ itemId: item.itemId, quantity: decodedQuantity }]
    };

    const queryParams = {
      show_sectors: false
    };
    return axios
      .post("/ebsn/api/wishlist/update_prods", params, { params: queryParams })
      .then(data => {
        if (data && data.data) {
          return data.data.data;
        } else {
          return {
            lists: [],
            page: null
          };
        }
      });
  }
};
