import CMSService from "~/service/cmService";
import { replaceHtmlEntities } from "~/service/ebsn";

import get from "lodash/get";

export default {
  data() {
    return { page: null, key: 1 };
  },
  computed: {
    content() {
      return get(this.page, "content.rendered");
    },
    title() {
      return get(this.page, "title.rendered");
    },
    validity() {
      return get(this.page, "testo_validit");
    },
    breadcrumb() {
      let pathArray = this.$route.path.split("/");
      pathArray.shift();
      const breadCrumbs = [{ to: "/", text: "Homepage" }];
      // needed to handle the intermediary entries for nested vue routes
      let breadcrumb = "";
      // let lastIndexFound = 0;

      for (let i = 0; i < pathArray.length; ++i) {
        breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
        if (
          this.$route.matched[i] &&
          Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
          Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
        ) {
          breadCrumbs.push({
            href: breadcrumb,
            // i !== 0 && pathArray[i - (i - lastIndexFound)]
            //   ? "/" + pathArray[i - (i - lastIndexFound)] + breadcrumb
            //   : breadcrumb,
            exact: false,
            disabled: i + 1 === pathArray.length,
            text: this.$route.matched[i].meta.breadCrumb || pathArray[i]
          });
          // lastIndexFound = i;
          // breadcrumb = "";
        } else if (i == pathArray.length - 1) {
          if (this.page) {
            let pageTitle = replaceHtmlEntities(
              get(this.page, "title.rendered")
            );
            breadCrumbs.push({
              link: true,
              text: pageTitle,
              to: { name: "Page", slug: this.page.slug }
            });
          }
        }
      }
      return breadCrumbs;
    }
  },
  methods: {
    async fetchPage(pageName) {
      let data = await CMSService.getPage(pageName);

      if (data.length > 0) {
        this.page = data[0];
        this.key++;
      }
    }
  },
  jsonld() {
    if (this.page) {
      return {
        "@context": "https://schema.org",
        "@type": "NewsArticle",
        mainEntityOfPage: {
          "@type": "WebPage",
          "@id": this.page.link
        },
        headline: this.page.meta ? this.page.meta.seo_title : "",
        image: ["/logo/social-logo.png"],
        author: {
          "@type": "Organization",
          name: global.config?.defaultPageName
        },
        datePublished: this.page.date,
        dateModified: this.page.date,
        publisher: {
          "@type": "Organization",
          name: global.config?.defaultPageName,
          logo: {
            "@type": "ImageObject",
            url: "/logo/social-logo.png"
          }
        }
      };
    }
  },
  metaInfo() {
    if (this.page) {
      return {
        title: get(this.page, "meta.seo_title"),
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}/page/${this.page.slug}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: get(this.page, "meta.seo_description")
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: get(
              this.page,
              "meta.seo_title",
              global.config?.defaultPageName
            )
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            property: "og:image:alt",
            content: get(this.page, "meta.seo_description")
          },
          {
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: get(this.page, "meta.seo_title", "Pagina statica")
          },
          {
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
