// plugins/meta.js

// plugins/i18n.js
import { VImg } from "vuetify/lib";
// eslint-disable-next-line no-unused-vars
import { defineAsyncComponent } from "vue";
export default {
  install: app => {
    let ebsn = {
      meta: function(targetObject, propertyName, defaultValue) {
        // retrieve a nested property in `options`
        // using `key` as the path
        if (
          !(
            propertyName.startsWith("productInfos") ||
            propertyName.startsWith("warehousePromo") ||
            propertyName.startsWith("metaData")
          )
        ) {
          propertyName = "metaData." + propertyName;
        }
        return propertyName.split(".").reduce((o, i) => {
          if (o && typeof o[i] !== "undefined") return o[i];
          else return defaultValue;
        }, targetObject);
      }
    };

    app.component("ebsn-meta", {
      functional: true,
      name: "ebsnMeta",
      props: {
        tag: {
          type: [String, Boolean, Object],
          default: "span"
        },
        path: {
          type: String,
          required: true
        },
        target: {
          type: Object
        },
        defaultValue: {
          type: String,
          required: false
        },
        cssClass: { type: String | Array, required: false }
      },
      render: function(createElement, context) {
        const { tag, path, target, defaultValue, cssClass } = context.props;
        const value = ebsn.meta(target, path, defaultValue);
        if (value) {
          if (tag) {
            return createElement(tag, {
              domProps: {
                innerHTML: value
              },
              attrs: {
                style: context.data.style,
                class: context.data.class
              },
              props: context.props,
              class: cssClass
            });
          } else {
            createElement(value);
          }
        } else {
          return null;
        }
      }
    });
    app.component("ebsn-img", {
      functional: true,
      name: "ebsnImg",
      props: {
        path: {
          type: String,
          required: true
        },
        target: {
          type: Object
        },
        defaultValue: {
          type: String,
          required: false
        },
        cssClass: { type: String | Array, required: false },
        width: { type: String, required: false },
        height: { type: String, required: false },
        alt: { type: String, required: false },
        title: { type: String, required: false },
        tag: { type: String, required: false, default: "img" }
      },
      render: function(createElement, context) {
        const {
          path,
          target,
          defaultValue,
          cssClass,
          width,
          height,
          alt,
          title,
          tag
        } = context.props;
        const value = ebsn.meta(target, path, defaultValue);
        if (value) {
          if (tag === "img") {
            return createElement(tag, {
              attrs: {
                src: value,
                class: cssClass,
                width: width,
                height: height,
                alt: alt,
                title: title,
                cssClass: cssClass
              }
            });
          } else if (tag === "v-img") {
            return createElement(VImg, {
              attrs: {
                src: value,
                class: cssClass,
                width: width,
                height: height,
                alt: alt,
                title: title,
                cssClass: cssClass
              }
            });
          }
        } else {
          return null;
        }
      }
    });
    app.component("ebsnStyle", {
      name: "ebsnStyle",
      props: {
        path: {
          type: String,
          required: true
        },
        target: {
          type: Object
        },
        styleWrapper: {
          type: String,
          required: false
        }
      },
      render: function(createElement) {
        const { path, target, styleWrapper } = this.$props;
        let value = ebsn.meta(target, path);
        if (value) {
          if (styleWrapper) {
            value = value.replace(
              /([^\r\n,{};(?!@)]+)(,|{)/g,
              styleWrapper + " " + "$1$2"
            );
          }
          return createElement(
            "style",
            {
              attrs: {
                type: "text/css"
              }
            },
            value
          );
        }
      }
    });
    // inject a globally available oject $ebsn method
    app.prototype.$ebsn = ebsn;
    app.$ebsn = ebsn;
  }
};
