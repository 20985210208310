<template>
  <v-container class="timeslot-selector fill-height mt-1">
    <v-layout fill-height column>
      <div class="d-flex flex-column justify-center mb-6">
        <ebsn-meta
          class="font-weight-bold grey--text text--darken-1 text-center ma-4 mb-4"
          :target="category"
          path="category_info.TITLE"
          tag="h2"
        />
        <ebsn-meta
          :target="category"
          path="category_info.DESCRIPTION"
          tag="div"
        />
        <div class="selector-subtitle">
          <p class="mb-2 text-center">
            <strong v-if="cart.shippingAddress.addressType == 'home'">{{
              $t("timeslots.addressSelectedDelivery")
            }}</strong>
            <strong v-else>{{ $t("timeslots.addressSelectedDrive") }}</strong>
            {{
              cart.shippingAddress.addressName +
                " - " +
                $t(
                  "navbar.address." +
                    cart.shippingAddress.addressTypeId +
                    ".list",
                  cart.shippingAddress
                )
            }}
          </p>
        </div>

        <!-- <v-icon small color="primary" right @click="changeAddress">
            $edit
          </v-icon> -->
      </div>

      <!-- <v-divider></v-divider> -->
      <v-tabs
        hide-slider
        icons-and-text
        v-model="tab"
        :height="80"
        center-active
        show-arrows
        v-if="timeslots.length > 0"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="timeslotDay in timeslots"
          :key="timeslotDay.dateIso"
          @click="selectTimeslotDay(timeslotDay)"
        >
          <div
            class="time-div d-flex flex-column align-center justify-space-around"
          >
            <template v-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
              <span class="day-number font-weight-bold">
                {{ $t("timeslots.today") }}
              </span>
            </template>
            <template v-else>
              <span class="day-string">
                {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
              </span>
              <span class="day-number font-weight-bold">
                {{ $dayjs(timeslotDay.dateIso).format("D") }}
              </span>
              <span class="month">
                {{ $dayjs(timeslotDay.dateIso).format("MMMM") }}
              </span>
            </template>
          </div>
        </v-tab>
      </v-tabs>
      <v-tabs v-else-if="loading === false">
        Non ci sono fasce disponibili per questa zona
      </v-tabs>
      <!-- <v-divider></v-divider> -->
      <v-tabs-items v-model="tab" class="my-4">
        <v-tab-item
          class="timeslot-container"
          v-for="timeslotDay in timeslots"
          :key="timeslotDay.dateIso"
        >
          <v-row>
            <v-col
              cols="12"
              md="6"
              v-for="timeslot in timeslotDay.timeslots"
              :key="timeslot.timeslotId"
            >
              <v-btn
                class="timeslot-button"
                :disabled="disabled"
                block
                large
                depressed
                @click="selectTimeslot(timeslot)"
                v-bind:class="[
                  timeslot.status,
                  timeslot.selected ? 'selected' : ''
                ]"
              >
                <div
                  class="timeslot-btn-content d-flex align-center"
                  :class="
                    cart.homeDelivery
                      ? 'justify-space-between'
                      : 'justify-center'
                  "
                >
                  <span class="timeslot-name">{{ timeslot.name }}</span>
                  <template v-if="cart.homeDelivery && timeslot.deliveryFeeId">
                    <span
                      class="delivery-fee promo-delivery-fee"
                      v-if="
                        getTimeslotGiftCertificate(timeslot) &&
                          getTimeslotGiftCertificateProposal(timeslot)
                      "
                    >
                      <span
                        class="promo-timeslot"
                        v-if="
                          getTimeslotGiftCertificateProposal(timeslot).descr
                        "
                      >
                        {{ getTimeslotGiftCertificateProposal(timeslot).descr }}
                        <br />
                      </span>
                      <span class="gift-cert-descr">{{
                        getTimeslotGiftCertificate(timeslot).description
                      }}</span>
                    </span>
                    <span
                      class="delivery-fee nopromo-delivery-fee"
                      v-else-if="getTimeslotGiftCertificate(timeslot)"
                    >
                      {{ getTimeslotGiftCertificate(timeslot).description }}
                    </span>
                    <span
                      class="delivery-fee"
                      v-else-if="timeslot.deliveryFeeRows"
                      >{{
                        $n(bestFee(timeslot.deliveryFeeRows), "currency")
                      }}</span
                    >
                    <span class="delivery-fee" v-else>{{
                      $n(cart.deliveryFee, "currency")
                    }}</span>
                  </template>
                  <template
                    v-else-if="cart.homeDelivery && !timeslot.deliveryFeeId"
                  >
                    <span class="delivery-fee promo-delivery-fee">
                      <span class="gift-cert-descr">GRATIS</span>
                    </span>
                  </template>
                </div>
              </v-btn>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-divider></v-divider>
      <!-- <v-spacer></v-spacer> -->
      <div
        v-html="'Legenda'"
        class="px-2 text-uppercase primary--text text--lighten-1"
      />
      <v-row class="legend align-center" no-gutters>
        <!-- <v-spacer></v-spacer> -->
        <v-col cols="4" sm="4" class="py-0">
          <div class="timeslot-legend empty">
            <span>{{ $t("timeslots.slotAttribute.empty") }}</span>
          </div>
        </v-col>
        <v-col cols="4" sm="4" class="py-0">
          <div class="timeslot-legend full">
            <span>{{ $t("timeslots.slotAttribute.full") }}</span>
          </div>
        </v-col>
        <v-col cols="4" sm="4" class="py-">
          <div class="timeslot-legend selected">
            <span>{{ $t("timeslots.slotAttribute.selected") }}</span>
          </div>
        </v-col>
        <!-- <v-spacer></v-spacer> -->
      </v-row>
    </v-layout>
  </v-container>
</template>
<style lang="scss">
.timeslot-selector {
  .timeslot-button.v-btn:not(.v-btn--round).v-size--large {
    padding-right: 0px;
    .v-btn__content {
      height: 100%;
      width: 100%;
      .timeslot-btn-content {
        width: 100%;
        font-weight: normal;
      }
      .delivery-fee {
        width: 100px;
        border-left: 1px solid var(--v-grey-lighten2);
        padding-left: 10px;
        font-weight: bold;
        height: 44px;
        display: flex;
        align-content: center;
        justify-content: center;
        flex-direction: column;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &.promo-delivery-fee {
          border: 1px solid #de1e1e;
          color: $primary-promo;
          line-height: 1.2;
          .promo-timeslot {
            font-size: 13px;
          }
          .gift-cert-descr {
            font-size: 15px;
          }
        }
        &.nopromo-delivery-fee {
          border: 1px solid #de1e1e;
          color: #de1e1e;
        }
      }
    }
    &.selected {
      .delivery-fee {
        &.promo-delivery-fee,
        &.nopromo-delivery-fee {
          color: var(--v-base-white);
          border: none;
          border-left: 1px solid white;
        }
      }
    }
  }
}
</style>
<script>
// @ is an alias to /src
import cartInfo from "~/mixins/cartInfo";

import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import get from "lodash/get";
import { mapState } from "vuex";

export default {
  name: "TimeslotSelector",
  mixins: [cartInfo],
  data() {
    return {
      timeslots: [],
      tab: 0,
      selectedTimeslotDay: {},
      disabled: false,
      category: {},
      loading: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    isDelivery() {
      return this.cart.shippingAddress.addressType == "home";
    },
    deliveryFee() {
      const totalVariation = this.cart.orderAdjustmentSummary
        ? this.cart.orderAdjustmentSummary.TYPE_SHIPPING_TOTAL.totalVariation
        : null;
      return this.cart.deliveryFee + totalVariation;
    }
  },
  methods: {
    getTimeslotGiftCertificate(timeslot) {
      return get(timeslot, "userGiftCertificates[0].giftCertificate");
    },
    getTimeslotGiftCertificateProposal(timeslot) {
      return get(
        timeslot,
        "userGiftCertificates[0].giftCertificate.categoryProposal"
      );
    },
    openAddressSelector() {
      this.$emit("submit", "setAddress");
    },
    bestFee(fee) {
      for (let i = 0; i < fee.length; i++) {
        if (this.cart.totalPrice <= fee[i].range) {
          return fee[i].fee;
        }
      }
    },
    checkIfIsToday(date) {
      return this.$dayjs().isSame(date, "day");
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      if (timeslot.active < 1) {
        return;
      }
      this.disabled = true;
      let data = await this.$store.dispatch("cart/setTimeslot", {
        dateIso: this.selectedTimeslotDay.dateIso,
        timeslotId: timeslot.timeslotId
      });
      if (data) {
        this.$emit("submit", true);
      }
    },

    async fetchTimeslots() {
      let vm = this;
      vm.loading = true;
      let timeslots = await DeliveryService.getTimeslotList(false);

      for (let i = 0; i < timeslots.length; i++) {
        if (timeslots[i].timeslots.length > 0) {
          vm.timeslots.push(timeslots[i]);
        }
      }
      // if (vm.timeslots.length > 1 && vm.timeslots[0].timeslots.length == 0) {
      //   vm.timeslots.push();
      // }

      for (let i = 0; i < vm.timeslots.length; i++) {
        if (vm.timeslots[i].dateIso == vm.cart.timeslot.date) {
          vm.tab = i;
          this.selectedTimeslotDay = vm.timeslots[i];
          break;
        }
      }
      vm.loading = false;
      // if (!vm.selectedTimeslotDay.dateIso) {
      //   vm.selectTimeslotDay(vm.timeslots[0]);
      // }
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug(
          "timeslot-selector"
        );
      } catch (e) {
        console.log(e);
      }
    }
  },

  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    this.disabled = false;
    this.fetchTimeslots();
    this.fetchCategory();
    // this.getDayTabName();
  }
};
</script>
