<template>
  <v-hover v-slot="{ hover }">
    <v-card
      rounded="md"
      class="product-card"
      :id="`product-card-${product.slug}`"
      :elevation="hover ? 5 : 0"
      :to="{ name: 'Product', params: { productSlug: product.slug } }"
      :key="componentKey"
    >
      <div
        class="product px-1 pt-0"
        :class="[promoClass]"
        @click="logClickProduct"
      >
        <div
          class="header d-flex flex-row align-center px-2"
          :class="`justify-${warehousePromoBody ? 'space-between' : 'end'}`"
        >
          <ProductPromo
            v-if="warehousePromoBody"
            :warehousePromo="product.warehousePromo"
          />
          <div class="icons-box">
            <ProductAcqLimit :product="product" />
            <v-tooltip
              left
              :key="heartKey"
              nudge-bottom="10"
              content-class="right-arrow"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  @click.stop.prevent="addToFavorites"
                  @mousedown.stop
                  class="heart"
                >
                  <v-icon color="secondary" v-if="highlight">$heartfull</v-icon>
                  <v-icon v-else color="secondary">$heart</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("product.addToFavorites") }}</span>
            </v-tooltip>
          </div>
        </div>
        <div class="d-flex body fill-width flex-column">
          <img
            :src="product.mediaURL"
            class="product-img align-self-center mt-2"
            :width="$vuetify.breakpoint.xs ? 100 : 150"
            :height="$vuetify.breakpoint.xs ? 100 : 150"
            :alt="'Immagine' + product.name"
            :title="
              product.name + ' (' + product.codInt + '-' + product.codVar + ')'
            "
          />
          <div class="description">
            <div class="product-name">
              {{ product.name }}
            </div>
            <div class="product-descr">
              <span v-if="product.description.length">
                {{ product.description }}
              </span>
            </div>
            <div class="product-brand">
              {{ product.shortDescr }}
              <span
                class="option"
                style="white-space: initial;text-align: right;"
                v-for="(value, label) in selectedOptions"
                :key="label"
              >
                Opzione <br class="xs-only-show" />selezionata:<b>
                  {{ value }}</b
                >
              </span>
            </div>
            <div
              class="product-descr"
              v-if="
                product.metaData &&
                  product.metaData.product_info &&
                  product.metaData.product_info.weight_description
              "
            >
              {{ product.metaData.product_info.weight_description }}
            </div>

            <ProductPrice :product="product" />
          </div>

          <v-row no-gutters class="actions">
            <template v-if="product.available > 0">
              <div class="qty-wrapper d-flex flex-row">
                <ProductQty
                  :key="key"
                  :selectedOptions="selectedOptions"
                  :product="product"
                  :item="item"
                />
                <v-menu
                  class="selectOptionsMenu mx-1"
                  top
                  left
                  :attach="`#product-card-${product.slug}`"
                  v-if="selectOptionsArray.length"
                  :position-x="0"
                  min-width="97%"
                  max-width="97%"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      outlined
                      v-on="on"
                      depressed
                      color="white"
                      height="42"
                      class="selectOptions ml-1"
                      @click.prevent.stop="setSelectOptions"
                    >
                      <v-icon large color="primary lighten-1">$list</v-icon>
                    </v-btn>
                  </template>
                  <v-list
                    dense
                    v-for="selectOption in selectOptionsArray"
                    :key="selectOption.name"
                    @click.prevent.stop
                    class="text-caption"
                  >
                    <v-list-item-title
                      v-if="selectOptionsArray.length > 1"
                      class="text-caption px-4 font-weight-bold"
                    >
                      {{ selectOption.name }}
                    </v-list-item-title>
                    <v-list-item
                      v-for="(option, idx) in selectOption.options"
                      :key="idx"
                      @click.prevent="onOptionChanged(selectOption, option)"
                    >
                      {{ option.key }}
                    </v-list-item>
                  </v-list>
                  <!-- <SelectOptionsMenu
                    :selectOptionsArray="selectOptionsArray"
                    :productSelectedOptions="selectedOptions"
                    @optionchanged="onOptionChanged"
                  /> -->
                </v-menu>
              </div>
            </template>
            <div v-else class="not-available">
              Il prodotto non è disponibile
            </div>
          </v-row>
        </div>
      </div>
    </v-card>
  </v-hover>
</template>
<style scoped lang="scss">
.v-input--dense {
  height: 60px !important;
  min-height: auto !important;
  .v-input__slot {
    min-height: auto !important;
  }
  .v-select__slot {
    min-height: auto !important;
    height: 35px !important;
  }
  .v-input__append-inner {
    margin-top: 5px !important;
  }
  .v-label {
    line-height: 13px;
    font-size: 14px !important;
  }
}
.xs-only-show {
  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: none;
  }
}
.option {
  font-size: 11px;
  height: 0;
  text-align: right;
  width: 100%;
}
.selectOptionsMenu {
  z-index: 12;
  // .v-menu__content {
  //   min-width: 100% !important;
  //   max-width: 100% !important;
  // }
}
.selectOptions {
  min-width: 10px !important;
  border: 1px solid var(--v-primary-lighten1);
  padding: 0 8px !important;
}
.not-available {
  color: red;
  font-size: 12px;
  white-space: normal;
  height: 40px;
  display: flex;
  align-items: flex-end;
  text-align: right;
}
:deep(.v-tooltip__content) {
  background: var(--v-primary-lighten2);
  color: $white;
  text-align: center;
  line-height: 1.5;
  max-width: 180px;
  font-weight: 600;
  padding: 2px 5px;
}
.display-option {
  font-size: 10px;
  position: absolute;
  top: 180px;
  right: 10px;
  background: var(--v-primary-base);
  color: $white;
  padding: 2px;
  border-radius: $border-radius-root;
}
</style>
<script>
//TODO sistemare la visualizzazione della card su iphone SE dimensione della description
// import ProductClass from "./ProductClass";
import ProductPromo from "./ProductPromo.vue";
import ProductAcqLimit from "./ProductAcqLimit.vue";
import ProductPrice from "./ProductPrice.vue";
// import SelectOptionsMenu from "./SelectOptionsMenu.vue";
import { formatProductCode } from "@/service/eurospinUtils";

import { mapState, mapGetters } from "vuex";
import productMixin from "~/mixins/product";
import ProductQty from "./ProductQty.vue";
import analyticsService from "~/service/analyticsService";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";
import forEach from "lodash/forEach";

export default {
  name: "ProductCard",
  props: { product: { type: Object, required: true } },
  // eslint-disable-next-line vue/no-unused-components
  components: {
    // ProductClass,
    ProductPromo,
    ProductAcqLimit,
    ProductPrice,
    ProductQty
    // ,
    // SelectOptionsMenu
  },
  data() {
    return {
      componentKey: 0,
      selectOptionsArray: [],
      selectedOptions: {},
      displayOption: null,
      key: 0
    };
  },
  mixins: [productMixin],
  created() {
    if (this.product.selectOptions.length > 0) {
      this.selectOptionsArray = cloneDeep(this.product.selectOptions);
    }
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      getItem: "cart/getItem"
    }),
    productCode() {
      return formatProductCode(this.product);
    },
    warehousePromoBody() {
      return get(this.product, "warehousePromo.view.body", null);
    },
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    item() {
      this.key;
      let item = this.getItem({
        product: this.product,
        ...this.selectedOptions
      });
      return item;
    },
    selectedOptionLabels() {
      let opts = [];
      if (this.selectedOptions) {
        forEach(this.selectedOptions, (value, key) => {
          if (key !== "notes") {
            opts.push(value);
          }
        });
      }
      return opts;
    }
  },
  methods: {
    logClickProduct() {
      analyticsService.clickProduct(this.product, this.position);
    },
    onOptionChanged(selectOption, option) {
      // this.optionChanged(selectOption);
      this.key++;
      this.selectedOptions[selectOption.parameterName] = option.key;
    },
    async setSelectOptions() {
      await this.needLogin("selectoptionsmenu");
    }
  }
};
</script>
