<template>
  <div
    class="flex-column mt-1 address-detail edit-address"
    v-if="internalValue"
  >
    <div class="address-title mt-3 mb-4 px-2 px-sm-0">
      <h1 v-html="titleSection"></h1>
    </div>

    <div v-if="showAutomplete">
      <v-form
        @submit.prevent.stop
        ref="form"
        class="add-address-form"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <!-- v-if="!validateAddress" -->
        <div class="d-flex px-2 px-sm-0">
          <vue-google-autocomplete
            id="map"
            classname="new-delivery-address"
            ref="address"
            :types="'geocode'"
            placeholder="Scrivi l'indirizzo di consegna"
            v-on:placechanged="getAddressData"
            :enable-geolocation="true"
            country="it"
            v-if="googleEnabled"
          >
          </vue-google-autocomplete>
          <v-tooltip left>
            <template v-slot:activator="{ on, attrs }">
              <!-- type="submit" -->
              <v-btn
                class="login-button"
                large
                depressed
                icon
                @click="geolocate"
                v-bind="attrs"
                v-on="on"
                ><v-icon>$placeholder</v-icon></v-btn
              >
            </template>
            {{ $t("profile.addresses.geolocalize") }}
          </v-tooltip>
        </div>

        <v-alert border="left" type="error" v-if="error">{{
          $t(error)
        }}</v-alert>
      </v-form>

      <div class="my-4 missing-address-data" v-if="missingAddressData">
        {{ missingAddressDataMessage }}
      </div>

      <!-- v-if="!validateAddress" -->
      <v-row class="address-actions mt-6" dense>
        <v-col cols="12" sm="6" class="d-flex justify-start">
          <v-btn
            v-if="showBackButton"
            color="secondary"
            outined
            large
            depressed
            outlined
            to="/profile/addresses"
          >
            {{ $t("profile.addresses.back") }}
          </v-btn>
          <v-btn
            v-else-if="openInModal"
            color="secondary"
            outined
            large
            depressed
            outlined
            @click="$emit('cancelAddAddress')"
          >
            {{ $t("profile.addresses.back") }}
          </v-btn>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex justify-end">
          <v-btn
            color="primary lighten-1"
            :disabled="checkAddressDisabled"
            @click="checkAddress"
            large
            depressed
          >
            {{ $t("profile.addresses.verify") }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <v-form
      v-if="!showAutomplete && homeDeliveryAvailable === true"
      @submit.prevent.stop
      ref="formSaveAddress"
      v-model="formSaveAddressValid"
      class="mt-1"
    >
      <div>
        <span class="d-block my-3 text-body-1">{{
          $t("profile.addresses.data")
        }}</span>
        <v-row dense>
          <v-col cols="12" sm="8" class="pb-0 d-flex align-center"
            ><v-text-field
              :label="$t('profile.addresses.city')"
              v-model="internalValue.city"
              readonly
              outlined
              filled
              dense
            >
            </v-text-field
          ></v-col>
          <v-col cols="6" sm="2" class="pb-0 d-flex align-center">
            <v-text-field
              :label="$t('profile.addresses.province')"
              v-model="internalValue.province"
              readonly
              outlined
              filled
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="6" sm="2" class="pb-0 d-flex align-center">
            <v-text-field
              :label="$t('profile.addresses.postalCode')"
              v-model="internalValue.postalcode"
              readonly
              outlined
              filled
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="7" class="pb-0 d-flex align-center">
            <v-text-field
              :label="$t('profile.addresses.address')"
              v-model="internalValue.address1"
              filled
              readonly
              outlined
              dense
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" sm="5" class="pb-0 d-flex align-center">
            <v-text-field
              :label="$t('profile.addresses.number')"
              @keypress.enter.prevent
              ref="addressNumberRef"
              @keyup="checkRequiredValues()"
              :rules="isAddressNumberRule"
              v-model="internalValue.addressNumber"
              :readonly="isReadOnly"
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
      </div>

      <!-- <span class="d-block text-center text-sm-left my-4 mb-sm-0 text-body-1">
        {{ $t("profile.addresses.deliveryYes") }}
      </span> -->
      <v-alert
        v-if="
          dataValidated &&
            dataValidated.zone &&
            dataValidated.zone.zoneGroupId &&
            dataValidated.zone.zoneGroupId === 5
        "
        type="success"
        class="shopopop"
        v-html="$t('profile.addresses.deliveryYesShopopop')"
      >
      </v-alert>
      <v-alert v-else type="success">
        {{ $t("profile.addresses.deliveryYes") }}
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center justify-sm-end mb-6 mb-sm-0 align-center pb-0"
        >
          <v-btn
            plain
            color="primary"
            @click="addAnotherAddress"
            large
            depressed
          >
            {{ $t("profile.addresses.modifyAddress") }}
          </v-btn>
        </v-col>
      </v-row>
      <span class="d-block my-3 text-body-1">{{
        $t("profile.addresses.complete")
      }}</span>
      <v-row>
        <v-col cols="12" sm="6" class="d-flex align-center pb-0">
          <v-text-field
            :label="$t('profile.addresses.type')"
            :placeholder="$t('profile.addresses.typePlaceholder')"
            v-model="internalValue.addressName"
            :rules="[requiredRule()]"
            @keyup="checkRequiredValues()"
            @keypress.enter.prevent
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-center pb-0">
          <v-text-field
            :label="$t('profile.addresses.name')"
            :placeholder="$t('profile.addresses.namePlaceholder')"
            v-model="internalValue.doorbellName"
            :rules="[requiredRule()]"
            @keyup="checkRequiredValues()"
            @keypress.enter.prevent
            outlined
            required
            dense
          >
          </v-text-field>
        </v-col>
        <v-row no-gutters class="flex prefix-container">
          <!-- PREFIX-->
          <v-col cols="2" sm="2" class="ml-3 prefix">
            <v-text-field
              v-model="internalValue.prefix"
              class="text-center"
              :placeholder="internalValue.prefix"
              disabled
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="7" sm="9" class="d-flex align-center pb-0">
            <v-text-field
              :label="$t('profile.addresses.phone')"
              :placeholder="$t('profile.addresses.phonePlaceholder')"
              v-model="internalValue.referencePhoneNumber"
              :rules="isMobilePhoneRules"
              @keyup="checkRequiredValues()"
              @keypress.enter.prevent
              required
              outlined
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <!-- <v-col cols="12" sm="6" class="d-flex align-center pb-0">
          <v-switch
            v-model="internalValue.ztl"
            :true-value="true"
            :false-value="false"
            inset
            :label="$t('profile.addresses.ztl')"
            dense
            class="mt-0"
            hide-details
          ></v-switch>
        </v-col> -->
        <v-col
          cols="6"
          sm="3"
          class="d-flex flex-column align-top pb-0 pt-2 mb-4 mb-sm-0"
        >
          <div>{{ $t("profile.addresses.reception") }}</div>
          <div class="d-flex flex-row align-center">
            <span class="text-caption">{{ $t("common.no") }}</span>
            <v-switch
              v-model="internalValue.apartmentNumber"
              :true-value="'true'"
              :false-value="'false'"
              inset
              dense
              class="ml-3 mt-0"
              hide-details
            ></v-switch>
            <span class="text-caption">{{ $t("common.yes") }}</span>
          </div>
        </v-col>

        <v-col
          cols="6"
          sm="3"
          class="d-flex flex-column align-top pb-0 pt-2 mb-4 mb-sm-0"
        >
          <div>{{ $t("profile.addresses.elevator") }}</div>
          <div class="d-flex flex-row align-center">
            <span class="text-caption">{{ $t("common.no") }}</span>
            <v-switch
              v-model="internalValue.elevator"
              :true-value="true"
              :false-value="false"
              inset
              dense
              class="ml-3 mt-0"
              hide-details
            ></v-switch>
            <span class="text-caption">{{ $t("common.yes") }}</span>
          </div>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-center pb-0">
          <v-text-field
            :label="$t('profile.addresses.doorbellNumber')"
            :placeholder="$t('profile.addresses.doorbellNumberPlaceholder')"
            v-model="internalValue.doorbellNumber"
            @keypress.enter.prevent
            outlined
            dense
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" class="d-flex align-center pb-0">
          <v-text-field
            :label="$t('profile.addresses.floor')"
            :placeholder="$t('profile.addresses.floorPlaceholder')"
            v-model="internalValue.floor"
            @keypress.enter.prevent
            outlined
            dense
            :rules="isFloorRule"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="12" class="d-flex align-center pb-0">
          <v-text-field
            :label="$t('profile.addresses.other')"
            :placeholder="$t('profile.addresses.otherPlaceholder')"
            v-model="internalValue.addressNote"
            @keypress.enter.prevent
            outlined
            dense
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" class="d-flex justify-space-between mb-4">
          <v-btn
            v-if="showSecondStepBackButton"
            color="secondary"
            outined
            large
            depressed
            outlined
            @click="$emit('cancelAddAddress')"
          >
            {{ $t("profile.addresses.back") }}
          </v-btn>
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            @click.prevent.stop="saveAddress"
            large
            depressed
          >
            <!-- :disabled="!validRequiredValues" -->
            {{ $t("profile.addresses.save") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <div v-if="homeDeliveryAvailable === false">
      <v-alert type="error">
        {{ $t("profile.addresses.deliveryNo") }}
      </v-alert>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center justify-sm-end mb-6 mb-sm-0"
        >
          <v-btn color="secondary" @click="addAnotherAddress" large depressed>
            {{ $t("profile.addresses.addAnotherAddress") }}
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col
          cols="14"
          class="d-flex justify-center align-top pb-0 pt-2 mb-4 mb-sm-0"
        >
          {{ $t("profile.addresses.deliveryContact") }}
          Vorresti rimanere a contatto con noi nel caso l'indirizzo dovesse
          essere attivo in futuro?
        </v-col>
      </v-row>
      <v-row>
      <v-col
          cols="14"
          class="d-flex justify-space-around">
        <v-btn outlined color="primary lighten-1" @click="addAnotherAddress" large depressed>
          NO
        </v-btn>
        <v-btn color="primary lighten-1" @click="notifyDelivery" large depressed>
          SÍ
        </v-btn>
          </v-col>
      </v-row> -->
    </div>

    <v-form
      v-if="notifyDeliveryYes === true"
      @submit.prevent.stop
      ref="formNotifyAddress"
      class="mt-1"
    >
      <!-- {{ $t("profile.addresses.deliveryYes") }} -->
      Ci dispiace, il tuo indirizzo non è ancora servito dalla Consegna a Casa!
      <br />
      Stiamo lavorando per estendere il servizio in tante nuove zone. Compila il
      form qui sotto per ricevere un avviso quando sarà attivo anche al tuo
      indirizzo.

      <div>
        <br />
        <v-row dense>
          <!-- FIRST NAME -->
          <v-col class="pr-sm-2" cols="12" sm="6">
            <v-text-field
              :label="$t('contact.name')"
              v-model="formData.firstName"
              :placeholder="`${$t('contact.name')} *`"
              :rules="[requiredRule()]"
              @keyup="checkNotifyRequiredValues()"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- LAST NAME -->
          <v-col class="pl-sm-2" cols="12" sm="6">
            <v-text-field
              :label="$t('contact.surname')"
              v-model="formData.lastName"
              :placeholder="`${$t('contact.surname')} *`"
              :rules="[requiredRule()]"
              @keyup="checkNotifyRequiredValues()"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- EMAIL -->
          <v-col class="pr-sm-2" cols="12" sm="6">
            <v-text-field
              :label="$t('contact.email')"
              v-model="formData.email"
              :placeholder="`${$t('contact.email')} *`"
              :rules="emailRules"
              :error-messages="errors"
              type="email"
              autocomplete="off"
              @keyup="checkNotifyRequiredValues()"
              required
              outlined
              dense
            ></v-text-field>
          </v-col>
          <!-- POSTAL CODE -->
          <v-col class="pr-sm-2" cols="12" sm="6">
            <v-text-field
              :label="$t('profile.addresses.postalCode')"
              :placeholder="`${$t('profile.addresses.postalCode')} *`"
              :rules="postalcodeRule"
              v-model="formData.postalCode"
              required
              outlined
              filled
              dense
            ></v-text-field>
          </v-col>
          <!-- PRIVACY -->
          <v-col class="py-0" cols="12">
            <v-checkbox
              :rules="checkboxRule"
              required
              v-model="formData.acceptPrivacyPolicies"
            >
              <template v-slot:label>
                <div>
                  {{ $t("contact.informativa.privacy") }}
                  <a
                    class="ml-n1"
                    target="_system"
                    href="https://laspesaonline.eurospin.it/p/wp-content/uploads/2023/04/eurospin_informativa_contatti_1_2023.pdf"
                    @click="clicked"
                  >
                    {{ $t("contact.privacyLink") }}
                  </a>
                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col cols="12" class="d-flex justify-space-between mb-4">
            <v-spacer />
            <v-btn
              @click.prevent.stop="saveAddressToNotify"
              type="submit"
              color="primary lighten-1"
              large
              depressed
              :disabled="
                !formData.acceptPrivacyPolicies && !validNotifyRequiredValues
              "
            >
              AVVISAMI
            </v-btn>
          </v-col>
        </v-row>
      </div>
    </v-form>
  </div>
</template>
<style scoped lang="scss">
:deep.shopopop a {
  color: white !important;
  font-weight: bold;
}
.address-detail {
  .section-title {
    font-size: 20px;
  }
  .add-address-form {
    #map {
      width: 100%;
      border: 1px solid var(--v-grey-base);
      border-radius: 5px;
      padding: 6px;
    }
  }
  .missing-address-data {
    color: var(--v-error-base);
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    .address-actions {
      .v-btn {
        width: 100%;
      }
    }
  }
  .v-autocomplete {
    .v-input__append-inner {
      display: none;
    }
  }
  .v-input--switch {
    .v-input__slot {
      background-color: transparent !important;
    }
  }
  .mandatory {
    font-size: 14px;
  }
}
.v-dialog {
  .prefix-container {
    width: 50%;
  }
}
@media #{map-get($display-breakpoints, 'sm-and-up')} {
  .profile-section {
    .prefix-container {
      .prefix {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 8.3333333333%;
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%;
      }
    }
  }
}
</style>
<script>
import GeoService from "~/service/geoService";
import DeliveryService from "~/service/deliveryService";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import gmapsInit from "@/plugins/gmapsinit";
import { mapActions } from "vuex";
import { mask } from "vue-the-mask";
import { isCAP, requiredValue } from "~/validator/validationRules";
import {
  isAddressNumber,
  isNumber,
  isEmail,
  isMobilePhoneStrict
} from "@/validator/validationRules";
import clickHandler from "~/mixins/clickHandler";

var google;

export default {
  name: "EditAddress",
  mixins: [clickHandler],
  components: { VueGoogleAutocomplete },
  props: {
    showBackButton: { type: Boolean, required: false, default: true },
    showSecondStepBackButton: {
      type: Boolean,
      required: false,
      default: false
    },
    openInModal: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      errors: [],
      valid: false,
      formSaveAddressValid: false,
      validRequiredValues: false,
      validNotifyRequiredValues: false,
      lazy: true,
      error: null,
      showAutomplete: true,
      homeDeliveryAvailable: null,
      googleEnabled: false,
      checkAddressDisabled: true,
      isReadOnly: true,
      notifyDeliveryYes: null,
      formData: {
        firstName: null,
        lastName: null,
        email: null,
        postalCode: null,
        acceptPrivacyPolicies: null,
        inputAddress: this.internalValue
      },
      isPrivacyPoliciesAccepted: null,
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      internalValue: {},
      address: null,
      provinceList: [],
      cityList: [],
      addressList: [],
      postalcodeList: [],
      requiredRule: requiredValue,
      isMobilePhoneRules: [isMobilePhoneStrict(), requiredValue()],
      isAddressNumberRule: [
        requiredValue("Campo obbligatorio"),
        isAddressNumber()
      ],
      postalcodeRule: [requiredValue("Digitare il codice postale"), isCAP()],
      isFloorRule: [requiredValue("Campo obbligatorio"), isNumber()],
      addressGeoCoded: null,
      validateAddress: false,
      missingAddressData: false,
      missingAddressDataMessage: null,
      addressDataInfo: [
        { field: "city", message: "città", required: true },
        { field: "address1", message: "indirizzo", required: true },
        { field: "postalcode", message: "CAP", required: true },
        { field: "province", message: "provincia", required: true },
        { field: "addressNumber", message: "civico", required: false }
      ],
      // floorItems: [
      //   "Piano terra",
      //   "1",
      //   "2",
      //   "3",
      //   "4",
      //   "5",
      //   "6",
      //   "Oltre il sesto"
      // ],
      selectedProvince: null,
      selectedCity: null,
      selectedAddress: null,
      city: null,
      address1: null
    };
  },
  directives: { mask },
  computed: {
    titleSection() {
      return this.internalValue.addressId
        ? this.$t("profile.addresses.editAddressTitle", [
            this.internalValue.addressName
          ])
        : this.$t("profile.addresses.newAddressTitle");
    },
    checkboxRule() {
      return [
        this.formData.acceptPrivacyPolicies === true || "Campo obbligatorio"
      ];
    }
  },
  methods: {
    ...mapActions({
      setAddress: "cart/setAddress"
    }),
    checkRequiredValues() {
      this.validRequiredValues =
        this.internalValue.addressName &&
        this.internalValue.doorbellName &&
        this.internalValue.referencePhoneNumber &&
        this.$refs.addressNumberRef.validate();
    },
    checkNotifyRequiredValues() {
      this.validNotifyRequiredValues =
        this.formData.firstName && this.formData.surname && this.formData.email;
    },
    resetForm() {},
    getAddressData(addressData, place) {
      //check @home
      let vm = this;
      var foundCity = false;
      this.missingAddressData = false;
      place.address_components.forEach(element => {
        if (element.types[0] == "administrative_area_level_2") {
          vm.internalValue.province = element.short_name;
          return;
        }
      });
      place.address_components.forEach(element => {
        if (
          !foundCity &&
          (element.types[0] == "administrative_area_level_3" ||
            element.types[0] == "locality" ||
            element.types[0] == "administrative_area_level_2")
        ) {
          vm.internalValue.cityAdditionalInfo = element.short_name;
          foundCity = true;
        }
      });

      vm.internalValue.city =
        addressData.administrative_area_level_3 ||
        addressData.locality ||
        addressData.administrative_area_level_2;
      if (
        !addressData.administrative_area_level_3 &&
        !addressData.locality &&
        !(vm.internalValue.city === vm.internalValue.cityAdditionalInfo)
      ) {
        vm.internalValue.city =
          vm.internalValue.city + " - " + vm.internalValue.cityAdditionalInfo;
      }
      vm.internalValue.postalcode = addressData.postal_code;
      vm.internalValue.addressNumber = addressData.street_number;
      vm.internalValue.address1 = addressData.route;
      vm.internalValue.latitude = addressData.latitude;
      vm.internalValue.longitude = addressData.longitude;
      vm.checkAddressDisabled = false;
    },
    async checkAddress() {
      let vm = this;
      vm.missingAddressData = false;
      vm.missingAddressDataMessage =
        "L'indirizzo inserito è incompleto, mancano i seguenti campi: ";
      vm.addressDataInfo.forEach(element => {
        if (element.required) {
          if (vm.internalValue[element.field] == undefined) {
            vm.missingAddressDataMessage += element.message + ", ";
            vm.missingAddressData = true;
          }
        }
      });
      if (vm.missingAddressData) {
        vm.missingAddressDataMessage = vm.missingAddressDataMessage.slice(
          0,
          -2
        );
        vm.missingAddressDataMessage += ".";
      } else {
        try {
          vm.dataValidated = await DeliveryService.isValidAddress({
            country: vm.internalValue.country,
            longitude: vm.internalValue.longitude,
            gpsLongitude: vm.internalValue.longitude,
            latitude: vm.internalValue.latitude,
            gpsLatitude: vm.internalValue.latitude,
            address1: vm.internalValue.address1,
            city: vm.internalValue.city,
            postalcode: vm.internalValue.postalcode,
            province: vm.internalValue.province,
            addressNumber: vm.internalValue.addressNumber
          });
          if (vm.dataValidated.active == 1) {
            vm.homeDeliveryAvailable = true;
            vm.showAutomplete = false;
          } else {
            vm.homeDeliveryAvailable = false;
            vm.showAutomplete = false;
          }
          if (vm.dataValidated.addressNumber) {
            vm.isReadOnly = true;
          } else {
            vm.isReadOnly = false;
          }
        } catch (err) {
          vm.homeDeliveryAvailable = false;
        }
        this.results = true;
      }
    },
    resetAddress() {
      this.selectedAddress = null;
      this.addressList = [];
      this.internalValue.address1 = null;
    },
    async getAddress(addressId) {
      this.internalValue = await DeliveryService.getAddresById(addressId);
      this.internalValue.prefix = "+39";
    },
    async geocodeAddress(e) {
      let vm = this;
      e.preventDefault();
      if (vm.$refs.form.validate()) {
        let isGecoded = false;
        if (vm.internalValue.latitude && vm.internalValue.longitude) {
          isGecoded = true;
        } else {
          let address = await GeoService.geocodeAddress(vm.internalValue);
          if (address) {
            Object.assign(vm.internalValue, address);
            isGecoded = true;
          }
        }

        if (isGecoded) {
          vm.internalValue.city = vm.internalValue.city.toUpperCase();
          vm.internalValue.province = vm.internalValue.province.toUpperCase();
          //vm.internalValue = address;
          // vm.addressGeoCoded = Object.assign({}, address, vm.internalValue);
          // vm.addressGeoCoded.city = vm.addressGeoCoded.city.formatted_address;
          // vm.addressGeoCoded.address1 =
          //   vm.addressGeoCoded.address1.formatted_address;

          // //? valido l'indirizzo geocodificato
          vm.validateAndSaveAddress();
        } else {
          vm.error = "message.invalidAddress1";
        }
      }
    },
    initInternalValue() {
      this.internalValue = {
        addressName: null,
        doorbellName: null,
        city: null,
        addressNote: null,
        postalcode: null,
        province: null,
        addressNumber: null,
        address1: null,
        floor: null,
        referencePhoneNumber: null,
        prefix: "+39",
        longitude: null,
        latitude: null,
        elevator: false,
        apartmentNumber: "false"
      };
    },
    addAnotherAddress() {
      this.showAutomplete = true;
      this.checkAddressDisabled = true;
      this.homeDeliveryAvailable = null;
      this.initInternalValue();
    },
    notifyDelivery() {
      this.notifyDeliveryYes = true;
      this.homeDeliveryAvailable = null;
    },
    geolocate() {
      let vm = this;
      if (navigator.geolocation) {
        document.getElementById("map").value = "";
        document.getElementById("map").placeholder =
          "Localizzazione in corso...";
        navigator.geolocation.getCurrentPosition(
          function(position) {
            var latlng = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            var geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, function(results, status) {
              if (status == google.maps.GeocoderStatus.OK) {
                vm.internalValue = GeoService.decodeGoogleAddress(results[0]);
                vm.internalValue.latitude = vm.internalValue.latitude();
                vm.internalValue.longitude = vm.internalValue.longitude();
                var addressFormat = GeoService.composeAddress(vm.internalValue);
                document.getElementById("map").value = addressFormat;
                document.getElementById("map").placeholder =
                  "Scrivi il tuo indirizzo";
                vm.checkAddress();
              } else {
                console.log(
                  "Geocode was not successful for the following reason: "
                );
              }
            });
            // var ll = new google.maps.LatLng(
            //   position.coords.latitude,
            //   position.coords.longitude
            // );
            // vm.$refs.address.updateCoordinates(ll);
          },
          function(err) {
            console.log(err);
            alert(
              "è necessario abilitare la localizzazione per utilizzare questa funzione"
            );
          },
          { enableHighAccuracy: false, maximumAge: Infinity }
        );
      }

      // this.$refs.address.geolocate();
    },
    async validateAndSaveAddress() {
      let vm = this;

      var dataValidated = await DeliveryService.isValidAddress(
        vm.internalValue
      );
      //? se non e' valido mostro snakcbar errore
      if (dataValidated.active === 0) {
        vm.error = "message.invalidAddress1";
      } else {
        if (
          !vm.internalValue.address1 ||
          !vm.internalValue.addressNumber ||
          !vm.internalValue.postalcode ||
          !vm.internalValue.city
        ) {
          vm.error = "message.invalidAddress1";
        } else {
          const text =
            "E' stato trovato questo indirizzo: <b>" +
            vm.internalValue.address1 +
            " " +
            vm.internalValue.addressNumber +
            ", " +
            vm.internalValue.postalcode +
            " " +
            vm.internalValue.city +
            "</b>. Vuoi salvarlo?";
          const dialogRes = await this.$dialog.confirm({ text });
          // eslint-disable-next-line no-unused-vars
          // _this.$emit("removeAddress", address);
          if (dialogRes) {
            vm.saveAddress();
          }
        }
      }
    },
    async saveAddress() {
      let vm = this;
      if (vm.$refs.formSaveAddress.validate()) {
        vm.internalValue.gpsLatitude = vm.internalValue.latitude;
        vm.internalValue.gpsLongitude = vm.internalValue.longitude;
        var addressUpdated = await DeliveryService.updateAddress(
          vm.internalValue
        );
        if (addressUpdated) {
          if (vm.openInModal) {
            vm.$emit("save", addressUpdated);
            return;
          }
          await vm.setAddress({
            addressType: "home",
            addressId: addressUpdated.addressId
          });
          vm.$emit("save", addressUpdated);
          vm.$router.push({
            name: "Addresses"
          });
        }
      }
    },
    async saveAddressToNotify() {
      let vm = this;
      vm.formData.inputAddress = vm.internalValue;
      console.log("FORM DATA:", vm.formData);
      if (vm.$refs.formNotifyAddress.validate()) {
        var addressUpdated = await DeliveryService.updateAddressToNotify(
          vm.formData
        );
        if (addressUpdated) {
          if (vm.openInModal) {
            vm.$emit("save", addressUpdated);
            return;
          }
        }
      }
    },
    back() {
      this.resetForm();
      this.$emit("back");
    }
  },
  watch: {
    address1(val, oldVal) {
      if (val && val.trim().length > 3 && val != oldVal) {
        this.fetchAddressSuggestion(val);
      }
    },
    city(val, oldVal) {
      if (val && val.trim().length > 0 && val != oldVal) {
        this.fetchCityList(val, this.provinceId);
      }
    }
  },
  async created() {
    this.initInternalValue();
    let vm = this;
    try {
      google = await gmapsInit(global.config.google_api_key);
      if (google) {
        this.googleEnabled = true;
      }
    } catch (err) {
      console.log(err);
    }
    if (vm.$route.params.addressId) {
      this.showAutomplete = false;
      this.homeDeliveryAvailable = true;
      await vm.getAddress(vm.$route.params.addressId);
    }
    // vm.fetchDefaultData();
    this.checkRequiredValues();
    this.checkNotifyRequiredValues();
  },
  mounted() {
    this.resetForm();
  }
};
</script>
