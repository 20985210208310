var render = function render(_c,_vm){return _c('div',{staticClass:"product-price"},[(_vm.props.product.priceDisplay)?_c('div',{staticClass:"price-container"},[(
          _vm.props.product.warehousePromo &&
            _vm.props.product.warehousePromo.view &&
            _vm.props.product.warehousePromo.view.bubble &&
            _vm.props.product.warehousePromo.view.bubble === 'hidden'
        )?_c('div'):[_c('div',{staticClass:"old-price"},[(_vm.props.product.priceStandardDisplay)?_c('span',[_vm._v(_vm._s(_vm.parent.$n(_vm.props.product.priceStandardDisplay, "currency")))]):_vm._e()])],_c('div',{staticClass:"price-cnt d-flex flex-row justify-space-between"},[_c('div',{staticClass:"cur-price",class:{ 'promo-price': _vm.props.product.priceStandardDisplay }},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceDisplay, "currency"))),(_vm.props.product.productInfos.TIPOLOGIA != 'Pezzo')?_c('span',{staticClass:"small text-lowercase"},[_vm._v("/"+_vm._s(_vm.props.product.weightUnitDisplay))]):_vm._e()]),(_vm.props.showDescrWeight)?_c('div',{staticClass:"product-descr-weight"},[(
              _vm.props.product.productInfos.TIPOLOGIA == 'Pezzo' &&
                _vm.props.product.priceUmDisplay
            )?_c('span',{staticClass:"weight-unit"},[_vm._v(" "+_vm._s(_vm.parent.$n(_vm.props.product.priceUmDisplay, "currency"))+"/"+_vm._s(_vm.props.product.weightUnitDisplay)+" ")]):_vm._e()]):_vm._e()])],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }