/* eslint-disable no-debugger */
import Login from "@/components/Login";
import TimeslotSelector from "@/components/delivery/TimeslotSelector";
import AddressSelector from "@/components/delivery/AddressSelector";
import StoreSelector from "@/components/delivery/StoreSelector";
import CartService from "~/service/cartService";
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      config: global.config,
      dialogInstance: null
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      isTimeslotAssigned: "cart/isTimeslotAssigned",
      isAddressAssigned: "cart/isAddressAssigned"
    })
  },
  methods: {
    ...mapActions({
      samlLogin: "cart/doSamlLogin"
    }),
    async openDialog(component, properties, fullscreen) {
      var defaultConfig = {
        waitForResult: false,
        fullscreen:
          fullscreen !== undefined
            ? fullscreen
            : this.$vuetify.breakpoint.smAndDown
      };
      var config = Object.assign({}, properties, defaultConfig);
      this.dialogInstance = await this.$dialog.show(component, config);
      const result = await this.dialogInstance.wait();
      return result;
    },
    async closeDialog() {
      await this.dialogInstance.close();
      return true;
    },
    async isLoggedIn(operation) {
      if (await CartService.isLoggedIn(operation)) {
        global.EventBus.$emit("restart");
      }
    },
    async setTimeslot() {
      let result = await this.openDialog(TimeslotSelector, {
        waitForResult: true,
        width: 750
      });
      if (result == "setAddress") {
        await this.setAddress();
        return await (this.isTimeslotAssigned ? true : this.setTimeslot());
      }
      // if result is type of object do the same but pass a delivery service id
      if (typeof result === "object" && result.action === "setAddress") {
        await this.setAddress(result.deliveryServiceId);
        return await (this.isTimeslotAssigned ? true : this.setTimeslot());
      }
      return result;
    },
    async setAddress(deliveryServiceId) {
      let result = await this.openDialog(AddressSelector, {
        waitForResult: true,
        width: 700,
        deliveryServiceId: deliveryServiceId
      });
      if (result == "setTimeslot") {
        this.setTimeslot();
        return false;
      }
      if (result == "setStore") {
        this.setStore();
        return false;
      }
      return result;
    },
    async setStore() {
      let result = await this.openDialog(StoreSelector, {
        waitForResult: true,
        width: 700
      });
      if (result == "setAddress") {
        this.setAddress();
        return false;
      }
      return result;
    },
    async doLogin(operation) {
      this.isLoggedIn(operation);
      let result = await this.openDialog(Login, {
        waitForResult: true,
        width: global.config.dialogMaxWidth || 600
      });
      return result;
    },
    async doSamlLogin(params) {
      this.samlLogin(params);
    },
    async needAddress() {
      return await (this.isAddressAssigned ? true : this.setAddress());
    },
    async needTimeslot() {
      return await (this.isTimeslotAssigned ? true : this.setTimeslot());
    },
    async needLogin(operation) {
      var _this = this;
      return await (_this.isAuthenticated ? true : _this.doLogin(operation));
    }
  },
  created() {
    this.config = global.config;
  }
};
