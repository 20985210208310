<template>
  <div class="static-page ninja-form" v-if="show">
    <v-container fluid v-if="form">
      <v-form
        ref="formRef"
        v-if="!formSent"
        v-model="valid"
        lazy-validation
        @submit.prevent="handleSubmit"
      >
        <v-row>
          <v-col
            cols="12"
            :class="
              field.container_class +
                ' col-num-' +
                idx +
                ' ' +
                (field.type === 'hidden' ? ' d-none' : '')
            "
            v-for="(field, idx) in sortedFields"
            :key="field.id"
          >
            <component
              :ref="field.id"
              :id="field.id"
              :dense="mergedOptions.dense"
              :outlined="mergedOptions.outlined"
              :filled="mergedOptions.filled"
              :solo="mergedOptions.solo"
              :flat="mergedOptions.flat"
              :depressed="mergedOptions.depressed"
              :background-color="mergedOptions.bgcolor"
              persistent-hint
              :is="decodeComponent(field.type)"
              :class="field.element_class || null"
              :label="field.label"
              v-model="field.value"
              :placeholder="field.placeholder"
              :hint="stripHTML(field.desc_text)"
              :required="field.required == '1'"
              :rules="decodeRules(field)"
              :autocomplete="decodeAutocomplete(field.type)"
              :items="
                field.options
                  ? field.options.map(prop => ({
                      text: prop.label,
                      value: prop.value
                    }))
                  : null
              "
              :type="
                field.type === 'number' || field.type === 'submit'
                  ? field.type
                  : null
              "
              :min="field.type === 'number' ? field.num_min : null"
              :max="field.type === 'number' ? field.num_max : null"
              :step="field.type === 'number' ? field.num_step : null"
              :accept="field.upload_types"
              :field="
                field.type === 'date' ||
                field.type === 'submit' ||
                field.type === 'checkbox'
                  ? field
                  : null
              "
              :menu="field.type === 'date' ? menu : null"
              :html="field.type === 'html' ? field.default : null"
              :data="
                field.type === 'listcheckbox' ||
                field.type === 'listradio' ||
                field.type === 'listmultiselect'
                  ? field
                  : null
              "
              @checkBoxChanged="setValue"
            >
              <template v-slot:label>
                <div @click="clicked" v-html="field.label"></div>
              </template>
              <template v-if="field.help_text" v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                      $info
                    </v-icon>
                  </template>
                  {{ stripHTML(field.help_text) }}
                </v-tooltip>
              </template>
            </component>
          </v-col>
        </v-row>
      </v-form>
      <v-alert type="success" v-if="formSent" v-html="successMessage"></v-alert>
      <v-alert
        dense
        class="my-4"
        type="error"
        v-if="invalidForm"
        v-html="errorMessage"
      ></v-alert>
    </v-container>
  </div>
</template>
<script>
import {
  VSelect,
  VTextField,
  VTextarea,
  VCheckbox,
  VFileInput,
  VDivider
} from "vuetify/lib";
import NinjaFormsDatePicker from "~/components/ninjaForms/NinjaFormsDatePicker.vue";
import NinjaFormsHtml from "~/components/ninjaForms/NinjaFormsHtml.vue";
import NinjaFormsSubmitBtn from "~/components/ninjaForms/NinjaFormsSubmitBtn.vue";
import NinjaFormsListCheckbox from "~/components/ninjaForms/NinjaFormsListCheckbox.vue";
import NinjaFormsListRadio from "~/components/ninjaForms/NinjaFormsListRadio.vue";
import NinjaFormsMultipleSelect from "~/components/ninjaForms/NinjaFormsMultipleSelect.vue";
import NinjaFormsCheckBox from "~/components/ninjaForms/NinjaFormsCheckBox.vue";

import clickHandler from "~/mixins/clickHandler";
import page from "~/mixins/page";

import OrderService from "~/service/orderService";
import CMService from "~/service/cmService";

import { mapGetters } from "vuex";
import orderBy from "lodash/orderBy";
import get from "lodash/get";
import merge from "lodash/merge";

import {
  requiredValue,
  isEmail,
  isGenericPhone,
  isCfIva,
  isCAP,
  isNumber,
  // minNum,
  maxLength,
  isCheckboxTrueCustom
} from "~/validator/validationRules";

export default {
  props: {
    formId: { type: Number },
    needLogin: { type: Boolean, required: false, default: false },
    options: { type: Object, required: false, default: () => ({}) },
    xdata: { type: Object, required: false }
  },
  data() {
    return {
      result: null,
      form: null,
      formSent: false,
      invalidForm: false,
      sortedFields: [],
      valid: true,
      menu: false,
      minNum: null,
      show: true,
      successMessage: "Grazie per averci inviato la tua richiesta",
      errorMessage: null,
      requiredRule: requiredValue,
      isGenericPhoneRule: [isGenericPhone()],
      emailRules: [isEmail()],
      isCfIvaRule: [isCfIva()],
      isCAP: [isCAP()],
      isNumber: [isNumber()],
      componentMap: [
        {
          component: "v-text-field",
          fieldTypes: [
            "textbox",
            "number",
            "address",
            "city",
            "zip",
            "email",
            "phone",
            "firstname",
            "lastname",
            "hidden"
          ]
        },
        {
          component: "v-select",
          fieldTypes: ["listselect", "listcountry"]
        },
        {
          component: "v-textarea",
          fieldTypes: ["textarea"]
        },
        {
          component: "NinjaFormsCheckBox",
          fieldTypes: ["checkbox"]
        },
        {
          component: "v-file-input",
          fieldTypes: ["file_upload"]
        },
        {
          component: "NinjaFormsDatePicker",
          fieldTypes: ["date"]
        },
        {
          component: "NinjaFormsSubmitBtn",
          fieldTypes: ["submit"]
        },
        {
          component: "NinjaFormsListCheckbox",
          fieldTypes: ["listcheckbox"]
        },
        {
          component: "NinjaFormsListRadio",
          fieldTypes: ["listradio"]
        },
        {
          component: "NinjaFormsMultipleSelect",
          fieldTypes: ["listmultiselect"]
        },
        {
          component: "NinjaFormsHtml",
          fieldTypes: ["html"]
        }
      ],
      autocompleteMap: [
        { autocomplete: "ddress-line1", fieldType: "address" },
        { autocomplete: "address-level2", fieldType: "city" },
        { autocomplete: "email", fieldType: "email" },
        { autocomplete: "given-name", fieldType: "firstname" },
        { autocomplete: "family-name", fieldType: "lastname" },
        { autocomplete: "tel", fieldType: "phone" },
        { autocomplete: "postal-code", fieldType: "zip" }
      ]
    };
  },
  components: {
    NinjaFormsDatePicker,
    NinjaFormsHtml,
    NinjaFormsSubmitBtn,
    NinjaFormsListCheckbox,
    NinjaFormsListRadio,
    NinjaFormsMultipleSelect,
    NinjaFormsCheckBox,
    VSelect,
    VTextField,
    VTextarea,
    VCheckbox,
    VFileInput,
    VDivider
  },
  mixins: [clickHandler, page],
  computed: {
    ...mapGetters({
      isAuthenticated: "cart/isAuthenticated",
      user: "cart/getUser"
    }),
    mergedOptions() {
      return merge(
        {
          dense: false,
          outlined: false,
          filled: false,
          solo: false,
          flat: false,
          depressed: false,
          bgcolor: undefined
        },
        this.options
      );
    }
  },
  methods: {
    async fetchForm(formId) {
      this.form = await CMService.getForm(formId);
      this.sortedFields = orderBy(this.form.fields, obj =>
        parseInt(obj.order, 10)
      );
      // this.title = this.form.settings.title;
      // this.page.title = { rendered: this.title };
      // this.page.meta = { seo_title: this.title, seo_descr: "" };
      Object.values(this.form.fields).forEach(async value => {
        if (value.default && value.default.indexOf("$") == 0) {
          value.value = get(
            this,
            value.default.substring(1, value.default.length),
            ""
          );

          if (value.element_class === "date-field") {
            value.value = this.$dayjs(value.value).format("DD MMMM YYYY");
          } else if (value.element_class === "time-field") {
            value.value = this.$dayjs(value.value, "HH:mm:ss").format("HH:mm");
          }
        }
        // vSelect on wp NinjaForm doesn't have default value, so we need to set on first select option
        let vSelectDefaul = get(value, "options[0].label", "");
        if (vSelectDefaul.indexOf("$") == 0) {
          if (vSelectDefaul == "$ordersList") {
            let ordersListMaxLenght = get(value, "options[0].value", null);
            let ordersList = await this.fetchOrdersList(
              ordersListMaxLenght
                ? ordersListMaxLenght
                : process.env.VUE_APP_ORDERS_LIST_PAGE_SIZE
            );
            value.options = ordersList;
          }
        }
      });
    },
    async fetchOrdersList(ordersNumber) {
      let result = await OrderService.getOrders(null, ordersNumber, true);
      let ordersListResult = result.orders.map(order => ({
        label: order.orderId,
        value: order.orderId
      }));
      return ordersListResult;
    },
    async handleSubmit() {
      if (this.$refs.formRef.validate()) {
        // FIX for new Ninja Form version: mapping each object field name as an ID with its numeric value
        for (const key in this.form.fields) {
          if (Object.prototype.hasOwnProperty.call(this.form.fields, key)) {
            const fieldValue = this.form.fields[key];
            fieldValue.id = parseInt(key);
          }
        }
        let result = await CMService.sendForm(this.form);
        if (result) {
          this.successMessage = result.actions.success_message;
          this.$emit("submit", true);
          this.$refs.formRef.reset();
          this.formSent = true;
          this.invalidForm = false;
        }
      } else {
        this.invalidForm = true;
        this.errorMessage = "La form non è stata compilata correttamente";
      }
    },
    decodeComponent(fieldType) {
      return this.componentMap.find(el => el.fieldTypes.includes(fieldType))
        .component;
    },
    decodeAutocomplete(fieldType) {
      let a = this.autocompleteMap.find(el => el.fieldType === fieldType);
      if (a) {
        return a.autocomplete;
      } else {
        return null;
      }
    },
    decodeRules(field) {
      let rules = [];

      if (field.required == "1") {
        rules.push(this.requiredRule());
        if (field.type === "checkbox") {
          rules.push(
            isCheckboxTrueCustom(
              "È obbligatorio accettare questo consenso per procedere"
            )
          );
        }
      }

      if (field?.input_limit) {
        rules.push(maxLength(field.input_limit));
      }

      if (field.type === "email") {
        rules.push(...this.emailRules);
      } else if (field.type === "phone") {
        rules.push(...this.isGenericPhoneRule);
      } else if (field.type === "zip") {
        rules.push(...this.isCAP);
      } else if (field.type === "number") {
        rules.push(...this.isNumber);
      }
      return rules;
    },
    stripHTML(str) {
      return str ? str.replace(/<[^>]*>?/gm, "") : null;
    },
    setValue(checkboxData) {
      const value = checkboxData.value;
      const field = checkboxData.field;
      for (const key in this.form.fields) {
        if (this.form.fields[key].key === field.key) {
          this.form.fields[key].value = value;
          break;
        }
      }
    }
  },
  metaInfo() {
    return {
      title: this.title,
      meta: [
        {
          vmid: "description",
          name: "description"
        }
      ]
    };
  },
  created() {
    this.fetchForm(this.formId ? this.formId : this.slug);
    if (this.needLogin && !this.isAuthenticated) {
      this.show = false;
    }
  }
};
</script>
